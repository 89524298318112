/* contact section styling */
@import "root.css";
@font-face {
    font-family: "The Scientist";
    src: url("../fonts/TheScientistRegular.woff2") format("woff2"),
      url("../fonts/TheScientistRegular.woff") format("woff"),
      url("../fonts/TheScientistRegular.ttf") format("truetype"),
      url("../fonts/TheScientistRegular.svg#TheScientistRegular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
section.contact {
	/* overflow: hidden; */
	padding: 2em 0;
	color: var(--light-font);
	width: 100%;
	height: fit-content;
}
section.contact .max-width {
	height: fit-content;
	max-width: 1300px;
	padding: 0 80px;
	margin: auto;
}
section.contact .contact-content {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	vertical-align: middle;
}

section.contact .contact-content .column {
	width: calc(50% - 30px);
}

section.contact .contact-content .text {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 10px;
}

section.contact .contact-content .left p {
	text-align: left;
}

section.contact .contact-content .left .icons {
	margin: 10px 0;
}

section.contact .contact-content .row {
	display: flex;
	height: 65px;
	align-items: center;
}

section.contact .contact-content .row .info {
	margin-left: 30px;
}

section.contact .contact-content .row i {
	font-size: 25px;
	color: var(--theme-color);
}

body.dark-theme section.contact .contact-content .row i {
	color: var(--theme-color-2);
}

section.contact .contact-content .info .head {
	font-weight: 500;
}
.signature {
	font-family: "The Scientist";
	font-size: 5vh;
	color: var(--theme-color);
}

/* Contact form*/

#container {
	width: 100%;
	background-color: var(--dark-background);
	/* border: solid 1px var(--border-color); */
	border-radius: 2em;
	box-shadow:  var(--contact-container-shadow-dark);
	overflow: hidden;
	color: var(--light-font);
	display: block;
	padding: 40px;
	box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

body.dark-theme #container {
	box-shadow: var(--contact-container-shadow-light);
	background: var(--light-background);
	color: var(--dark-font);
}

label {
	content: attr(type);
	display: block;
	font-size: 20px;
	padding: 0.5em;
}

fieldset {
	border: none;
}

input[type="text"] {
	background: transparent;
	border: none;
	outline: none;
	border-radius: 2em;
	height: 100%;
	width: 100%;
	box-shadow: var(--contact-field-shadow-dark);
	transition: all 0.2s ease-in-out;
	padding: 12px;
	color: var(--light-font);
	font-family: "Open Sans", sans-serif;
    margin: 2em 0;
}

body.dark-theme input[type="text"]{
    box-shadow: var(--contact-field-shadow-light);
    color: var(--dark-font);
}

input[type="text"]:hover {
	box-shadow: var(--contact-field-shadow-hover-dark);
}

body.dark-theme input[type="text"]:hover {
    box-shadow: var(--contact-field-shadow-hover-light);
}

input[type="password"] {
	background: transparent;
	border: none;
	outline: none;
	border-radius: 2em;
	height: 100%;
	width: 100%;
	box-shadow: var(--contact-field-shadow-dark);
	transition: all 0.2s ease-in-out;
	padding: 12px;
	color: var(--light-font);
	font-family: "Open Sans", sans-serif;
    margin: 2em 0;
}

body.dark-theme input[type="password"]{
    box-shadow: var(--contact-field-shadow-light);
    color: var(--dark-font);
}

input[type="password"]:hover {
	box-shadow: var(--contact-field-shadow-hover-dark);
}

body.dark-theme input[type="password"]:hover {
    box-shadow: var(--contact-field-shadow-hover-light);
}

input[type="email"] {
	background: transparent;
	border: none;
	outline: none;
	border-radius: 2em;
	height: 100%;
	width: 100%;
	box-shadow: var(--contact-field-shadow-dark);
	transition: all 0.2s ease-in-out;
	padding: 12px;
	color: var(--light-font);
}

body.dark-theme input[type="email"]{
    box-shadow: var(--contact-field-shadow-light);
    color: var(--dark-font);
}

input[type="email"]:hover {
	box-shadow: var(--contact-field-shadow-hover-dark);
}

body.dark-theme input[type="email"]:hover {
	box-shadow: var(--contact-field-shadow-hover-light);
}

textarea {
	background: transparent;
	border: none;
	outline: none;
	border-radius: 2em;
	height: auto;
	width: 100%;
	box-shadow: var(--contact-field-shadow-dark);
	transition: all 0.2s ease-in-out;
	padding: 15px;
	color: var(--light-font);
	resize: vertical;
	overflow: auto;
}

body.dark-theme textarea{
    box-shadow: var(--contact-field-shadow-light);
    color: var(--dark-font);
}

textarea:hover {
	box-shadow: var(--contact-field-shadow-hover-dark);
}

body.dark-theme textarea:hover {
	box-shadow: var(--contact-field-shadow-hover-light);
}

input[type="submit"] {
	background: transparent;
	border: none;
	outline: none;
	border-radius: 2em;
	height: 40px;
	width: 100px;
	box-shadow: var(--button-shadow-in-dark);
	transition: all 0.2s ease-in-out;
	padding: 0px 10px;
	color: var(--light-font);
	cursor: pointer;
	letter-spacing: 0.07em;
}

body.dark-theme input[type="submit"]{
    box-shadow: var(--button-shadow-in-light);
    color: var(--dark-font);
}

input[type="submit"]:hover {
	box-shadow: var(--button-shadow-out-dark);
}

body.dark-theme input[type="submit"]:hover {
	box-shadow: var(--button-shadow-out-light);
}

@media (max-width: 800px) {
    
    section.contact .contact-content .column {
		width: 100%;
		margin-bottom: 35px;
	}
}

@media (max-width: 600px){
    section.contact .max-width{
        padding: 0 23px;
    }
}

@media (min-width: 700px) and (max-width: 1000px) {
	section.contact .contact-content .column {
		
		margin-bottom: 35px;
	}
}
