@import "root.css";
@import url("https://fonts.googleapis.com/css?family=Jura:400");

.Modal {
	position: absolute;
	top: 40px;
	left: 40px;
	right: 40px;
	bottom: 40px;
}

.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 50%;
	height: 70%;
}

.login-button{
	text-align: center;
	padding: 1em;
	border: solid 2px black;
}

.login-page{
	text-align: center;
	justify-content: center;
	height: 75vh;
	padding: 15% 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

section#entry-page {
	position: relative;
	display: block;
	width: 100%;
	height: fit-content;
	box-sizing: border-box;
}
section#entry-page form {
	margin: auto 0;
	padding: 20px;
	background-color: var(--dark-background);
	border-radius: 10px;
	box-shadow:  var(--border-shadow-dark)
}

body.dark-theme section#entry-page form {
	background-color: var(--light-background);
	box-shadow:  var(--border-shadow-light)
	
}
section#entry-page form h2 {
	margin-bottom: 5px;
	text-align: center;
	font-size: 30px;
	font-weight: 100;
}
section#entry-page form fieldset {
	margin: 0;
	background-color: var(--dark-background);
	border: solid 1px var(--avatar-border);
	border-radius: 5px;
	
}
body.dark-theme section#entry-page form fieldset {
	background-color: var(--light-background);
}

section#entry-page form fieldset legend {
	padding: 5px;
	background-color: var(--dark-background);
	border-radius: 5px;
}

body.dark-theme section#entry-page form fieldset legend{
	background-color: var(--light-background);
}
section#entry-page form fieldset ul {
	margin: 0;
	padding: 0;
}
section#entry-page form fieldset ul li {
	list-style: none;
	height: fit-content;
	align-items: center;
	margin: 10px;
}
section#entry-page form fieldset ul li a {
	color: #02c;
}
section#entry-page form fieldset ul li em {
	grid-column: span 2;
	text-align: center;
	padding: 5px;
}
section#entry-page form fieldset ul li label {
	text-align: left;
	padding-bottom: 2px;
}

section#entry-page form fieldset ul li input {
	margin: 0;
}

section#entry-page form button {
	color: var(--light-font);
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 1.5em;
	background: var(--dark-background);
	box-shadow: var(--button-shadow-in-dark);
	margin-top: 1em;
}
body.dark-theme section#entry-page form button {
	color: var(--dark-font);
	background: var(--light-background);
	box-shadow: var(--button-shadow-in-light);
}
section#entry-page form button:hover {
	color: var(--theme-color);
	box-shadow: var(--button-shadow-out-dark);
}
body.dark-theme section#entry-page form button:hover {
	color: var(--theme-color);
	box-shadow: var(--button-shadow-out-light);
}

@media only screen and (min-width: 420px) {
	form h2 {
		font-size: 40px;
	}
	form fieldset ul li {
		grid-template-columns: 100px 1fr;
	}
	form fieldset ul li label {
		padding-right: 10px;
		padding-bottom: 0;
		text-align: right !important;
	}
}
@media (max-width: 600px) {
	.Overlay{
		width: 100%;
	}
}
@media (max-width: 800px) {
	.Overlay{
		width: 100%;
	}
}
