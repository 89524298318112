/*  import google fonts */

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400");

@import "root.css";

html {
	scroll-behavior: smooth;
}

body {
	background-color: var(--dark-background);
}

body.dark-theme {
	background-color: var(--light-background);
}

/* custom scroll bar */

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* all similar content styling codes */

section.about {
	overflow: hidden;
	padding: 0 150px 50px;
	color: var(--light-font);
}

body.dark-theme section .about {
	color: var(--dark-font);
}

h1 .about,
h2 .about,
h3 .about,
h4 .about,
h5 .about,
p .about,
small .about {
	font-family: "Open Sans", sans-serif;
}

.about h1 {
	font-size: 5vw;
	text-align: center;
	font-weight: 800;
}

.about h3 {
	font-size: 40px;
	letter-spacing: 5px;
}

.about h3 span {
	color: var(--theme-color);
}

.about h4 {
	font-size: 20px;
}

.about h5 {
	font-size: 15px;
	font-weight: 200;
}

.about small {
	font-size: 10px;
	letter-spacing: 0.5em;
}

.about p {
	font-size: 1.2em;
	font-style: italic;
	font-weight: 400;
}
.about p i {
	color: var(--theme-color);
}

.summary {
	letter-spacing: 0.5px;
	padding: 0 200px;
}

.card-1 {
	display: flex;
	padding-top: 0;
	width: auto;
	height: fit-content;
	background-color: var(--dark-background);
}

body.dark-theme .card-1 {
	background-color: var(--light-background);
}

.about .left-block {
	width: 40%;
	font-family: "Open Sans", sans-serif;
	font-weight: 300;
	margin-inline: 0 30px;
}

.about .left-block table {
	width: 90%;
	margin-right: 30px;
	text-align: left;
}

table th {
	color: var(--theme-color);
}

body.dark-theme table th {
	color: var(--theme-color-2);
}

table td {
	width: 60%;
	font-weight: 500;
}

.about .left-block a {
	display: inline-block;

	font-family: "Open Sans", sans-serif;
	color: var(--light-font);
	font-size: 15px;
	padding: 10px 15px;
	margin-top: 40px;
	font-weight: 400;
	border-radius: 1.5em;
	transition: all 0.3s ease;
	box-shadow: var(--button-shadow-in-dark);
}

body.dark-theme .about .left-block a {
	color: var(--dark-font);
	box-shadow: var(--button-shadow-in-light);
}

.about .left-block a:hover {
	color: var(--theme-color);

	box-shadow: var(--button-shadow-out-dark);
}

body.dark-theme .about .left-block a:hover {
	color: var(--dark-font);
	box-shadow: var(--button-shadow-out-light);
}

/* Hobbies and interest container */

.about .right-block {
	width: 60%;
	color: var(--light-font);
	overflow: hidden;
}

body.dark-theme .about .right-block {
	color: var(--dark-font);
}

.hobbies-container {
	float: left;
	padding: 20px;
}

.hobby-circle {
	position: relative;
	float: left;
	width: 100px;
	height: 100px;
	background-color: var(--dark-card);
	border-radius: 50%;
	text-align: center;
	padding: 25px;
	margin-inline-end: 15px;
	margin-block-end: 10px;
	box-shadow: var(--border-shadow-dark);
}

body.dark-theme .hobby-circle {
	background-color: var(--light-card);
	box-shadow: var(--border-shadow-light);
}

.hobby-circle i {
	font-size: 25px;
	margin-block-end: 5px;
}

/* Education & Experience  */

.edu-container {
	/* display: flex; */
}

.edu-container::after {
	content: "";
	display: inline-table;
	clear: both;
}

/* .edu-box {
  width: 33%;
  border-left: solid 5px;
  padding: 20px;
  float: left;
} */
.edu-box {
	float: left;
	width: 33%;
	padding: 20px;
	/* margin-block-end: 20px; */
	border-left: solid 5px;
	overflow: hidden;
}

.edu-box i {
	position: absolute;
	margin-left: -30px;
	margin-top: 7px;
	color: var(--theme-color);
}

.edu-box a {
	font-family: "Open Sans", sans-serif;
	color: var(--theme-color);
}

.banner-container {
	padding: 100px 20px;
}

.banner {
	text-align: center;
	width: 100%;
	height: fit-content;
}

.banner p {
	font-size: 25px;
	color: var(--light-font);
}

body.dark-theme .banner p {
	color: var(--dark-font);
}

.banner a {
	font-family: "Open Sans", sans-serif;
	display: inline-block;
	background: var(--theme-color);
	color: var(--dark-font);
	font-size: 25px;
	padding: 10px;
	margin-top: 20px;
	font-weight: 400;
	border-radius: 6px;
	transition: all 0.3s ease;
}

body.dark-theme .banner a {
	color: var(--light-font);
}

.banner a:hover {
	color: var(--light-font);
	background: var(--dark-background);
}

body.dark-theme .banner a:hover {
	color: var(--dark-font);
	background: var(--light-background);
}

@media (max-width: 900px) {
	section.about {
		padding: 0 50px 30px;
	}

	.max-width {
		padding: 0 50px;
	}

	.summary,
	.card-1,
	.edu {
		display: block;
		padding: 0 23px;
	}

	.about .card-1 {
		display: block;
	}

	.about .left-block {
		width: 100%;
	}

	.about .right-block {
		padding: 30px 0;
		width: 100%;
	}

	.hobbies-container {
		float: none;
		width: fit-content;
		overflow: hidden;
	}

	.hobby-circle {
		position: relative;
		justify-self: center;
		float: left;
		width: 120px;
		height: 120px;
		border-radius: 50%;
		text-align: center;
		padding: 30px;
		margin-inline: 5px;
		margin-block-end: 10px;
	}

	.about .left-block {
		width: 400px;
		font-family: "Open Sans", sans-serif;
		font-weight: 200;
		margin-inline: 0 30px;
	}

	.about .left-block table {
		width: 90%;
		margin-right: 30px;
		text-align: left;
	}
}

@media (min-width: 901px) and (max-width: 1150px) {
	section.about {
		padding: 0 50px 30px;
	}
}

@media (min-width: 690px) and (max-width: 890px) {
	.edu-box {
		width: 50%;
	}
}

@media (max-width: 690px) {
	section.about {
		padding: 0 30px 30px;
	}

	.max-width {
		padding: 0 23px;
	}
	.edu-container {
		flex-direction: column;
	}

	.edu-box {
		width: 100%;
	}
}

@media (max-width: 500px) {
	.scroll-up-btn {
		right: 15px;
		bottom: 15px;
		height: 38px;
		width: 35px;
		font-size: 23px;
		line-height: 38px;
	}
}
@media (max-width: 400px) {
	.max-width {
		padding: 0 5%;
	}
	.container {
		display: none;
	}
	.about .left-block {
		width: 100%;
	}
	.about .right-block {
		width: 100%;
	}
}
