/* navbar styling */

.navbar {
	position: relative;
	width: 100%;
	z-index: 999;
	padding: 20px 0;
	font-family: "Comfortaa";
	transition: all 0.3s ease;
	/* box-shadow: 3px 3px 5px #babecc, -5px -5px 10px #fff; */
	box-shadow: var(--border-shadow-dark);
	border-radius: 2em;
}

body.dark-theme .navbar{
	box-shadow: var(--border-shadow-light);
}

.navbar .max-width {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.navbar .logo a {
	color: var(--light-font);
	font-size: 35px;
	font-weight: 600;
}

body.dark-theme .navbar .logo a {
	color: var(--dark-font);
}

.navbar .logo a span {
	color: var(--theme-color);
	transition: all 0.3s ease;
}

body.dark-theme .navbar .logo a span {
	color: var(--theme-color);
}

.navbar .menu {
	margin-right: 50px;
}

.navbar .menu li {
	list-style: none;
	display: inline-block;
}

.navbar .menu li a {
	display: block;
	color: var(--light-font);
	font-size: 18px;
	font-weight: 500;
	margin-left: 25px;
	transition: color 0.3s ease;
}

body.dark-theme .navbar .menu li a {
	color: var(--dark-font);
}

.navbar .menu li a:hover {
	color: var(--theme-color);
}

body.dark-theme .navbar .menu li a:hover {
	color: var(--theme-color);
}

/* menu btn styling */

.menu-btn {
	color: var(--light-font);
	font-size: 23px;
	cursor: pointer;
	display: none;
}

body.dark-theme .menu-btn {
	color: var(--dark-font);
}

/* media query */
@media (max-width: 1040px) {
	.menu-btn {
		display: block;
		z-index: 999;
	}

	.menu-btn i.active:before {
		content: "\f00d";
	}

	body.dark-theme .menu-btn i.active:before {
		color: var(--dark-font);
	}

	.navbar .menu {
		position: fixed;
		height: 100vh;
		width: 100%;
		left: -100%;
		top: 0;
		background: #111;
		text-align: center;
		padding-top: 80px;
		transition: all 0.3s ease;
	}

	/*change menu background to light-color in day mode*/
	body.dark-theme .navbar .menu {
		background: var(--light-background);
		color: var(--dark-font);
	}

	.navbar .menu.active {
		left: 0;
	}

	.navbar .menu li {
		display: block;
	}

	.navbar .menu li a {
		display: inline-block;
		margin: 20px 0;
		font-size: 25px;
	}

	body.dark-theme .navbar .menu li a {
		color: var(--dark-font);
	}
}
