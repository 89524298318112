:root {
	--dark-background: #272727;
	/* --light-background: #fffdf6; */
	--light-background: #f1f3f6;
	--light-font: #d1d1d1;
	--dark-font: #272a2e;
	--light-card: #f1f3f6;
	--dark-card: #282828;
	--light-article: #f1f3f6;
	--dark-links: #978826;
	--light-links: #5c5470;
	--links-hover: #a03c56;
	--theme-color: #9896f1;
	--theme-color-2: #9896f1;
	--border-color: #4e4e4e;
	--border-color-2: #868686;
	--tech-cell: #535353;
	--avatar-border: #d1d1d1;
	--code-background-inline-light: #ebebeb;
	--code-background-inline-dark: #f7f7f7;
	--border-shadow-light: 5px 5px 15px #e6e4dd, -5px -5px 15px #ffffff;
	--border-shadow-dark: 5px 5px 15px #141414, -5px -5px 15px #3a3a3a;
	--button-shadow-in-light: inset 2px 2px 5px #babecc, inset -5px -5px 10px #fff;
	--button-shadow-out-light: -2px -2px 5px #fff, 2px 2px 5px #babecc;
	--button-shadow-in-dark: inset 2px 2px 5px #101010,
		inset -5px -5px 10px #141414;
	--button-shadow-out-dark: -2px -2px 5px #141414, 2px 2px 5px #101010;
	--contact-container-shadow-light: 3px 3px 5px #babecc, -5px -5px 10px #fff;
	--contact-container-shadow-dark: 3px 3px 5px #101010, -5px -5px 10px #141414;
	--contact-field-shadow-hover-light: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
	--contact-field-shadow-hover-dark: inset 1px 1px 2px #101010, inset -1px -1px 2px #141414;
	--contact-field-shadow-light: inset 2px 2px 5px #babecc, inset -5px -5px 10px #fff;
	--contact-field-shadow-dark: inset 2px 2px 5px #101010, inset -5px -5px 10px #141414;

}
