@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");

/* primary */
$moderate-blue: #9896f1;
$soft-red: hsl(358, 79%, 66%);
$light-grayish-blue: hsl(239, 57%, 85%);
$pale-red: hsl(357, 100%, 86%);

/* neutral */
$dark-blue: #272a2e;
$grayish-blue: hsl(211, 10%, 45%);
$light-gray: #d1d1d1;
$very-light-gray: hsl(228, 33%, 97%);
$white: #f1f3f6;

/* typography */
$ff-rubik: "Rubik", sans-serif;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
