/* footer section styling */

footer {
  
  
  color: var(--light-font);
  text-align: center;
  font-family: "Comfortaa";
  display: flex;
  justify-content: center;
 
}

footer span {
  background: var(--dark-background);
  padding: 15px 23px;
  box-shadow: var(--border-shadow-dark);
  border-radius: 2em;
}

body.dark-theme footer span {
  background: var(--light-background);
  box-shadow: var(--border-shadow-light);
  color: var(--dark-font);
}



footer span a,
footer span i {
  color: var(--theme-color);
  text-decoration: none;
}

footer span a:hover {
  text-decoration: none;
}
