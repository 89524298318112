/*  import google fonts */

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,600,900");
@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");

@import "root.css";

/* @font-face {
  font-family: "The Scientist";
  src: url("/font/TheScientistRegular.woff2") format("woff2"),
    url("/font/TheScientistRegular.woff") format("woff"),
    url("/font/TheScientistRegular.ttf") format("truetype"),
    url("/font/TheScientistRegular.svg#TheScientistRegular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
}

html {
	scroll-behavior: smooth;
}

body {
	background-color: var(--dark-background);
	padding: 0 0 1em;
}

body.dark-theme {
	background-color: var(--light-background);
	padding: 0 0 1em;
}

/* custom scroll bar */

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* all similar content styling codes */

section {
	
	color: var(--light-font);
	/* border-bottom: solid var(--border-color) 2px; */
}
 
body.dark-theme section {
	color: var(--dark-font);
}

.max-width {
	max-width: 1300px;
	padding: 0 80px;
	margin: auto;
}

.services,
.teams {
	padding: 0 0 100px;
	font-family: "Open Sans", sans-serif;
}

.home .home-content,
.services .serv-content {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	vertical-align: middle;
	height: 80vh;
}

section .title {
	position: relative;
	text-align: center;
	font-size: 40px;
	font-weight: 600;
	margin-bottom: 30px;
	/* padding-bottom: 20px; */
	font-family: "Comfortaa";
	text-decoration: underline var(--theme-color);
}

/* home section styling */

.home .max-width {
	margin: 0 0 auto 15px;
}

.home .home-content .text-1 {
	font-size: 1.5em;
}

.home .home-content .text-2 {
	font-size: 5em;
	font-weight: 900;
	font-family: "Raleway";
	position: relative;
	bottom: 0;
	/* text-decoration: solid underline var(--theme-color); */
}

.home .home-content .text-3 {
	font-size: 1.5em;
	margin: 10px 0;
}

/* Quote section styling */

.quote {
	width: 100%;
	height: fit-content;
	font-size: 3vw;
	font-weight: 300;
	color: var(--dark-font);
	background-color: var(--theme-color);
	padding: 5px 0;
	font-family: "Parisienne", cursive;
	word-spacing: 1px;
	letter-spacing: 2px;
	text-align: center;
}

body.dark-theme .quote {
	color: var(--light-font);
	background-color: var(--dark-background);
}

/* home section styling */

.home .home-content .right {
	width: 45%;
}

.home .home-content .right img {
	height: 400px;
	width: 400px;
	object-fit: cover;
	border-radius: 6px;
	border: solid var(--border-color) 2px;
}

.home .home-content .left {
	width: 100%;
}

.home .home-content .left a {
	display: inline-block;
	color: var(--light-font);
	font-size: 20px;
	font-weight: 500;
	padding: 10px 30px;
	margin: 20px 0;
	border-radius: 2em;
	transition: all 0.3s ease;
	box-shadow: var(--button-shadow-in-dark);
}

body.dark-theme .home .home-content .left a {
	color: var(--dark-font);
	box-shadow: var(--button-shadow-in-light);
}

.home .home-content .left a:hover {
	box-shadow: var(--button-shadow-out-dark);
}

body.dark-theme .home .home-content .left a:hover {
	box-shadow: var(--button-shadow-out-light);
}

/* @media (max-width: 1000px) {
  .tech-container {
    max-width: 100%;
  }
  .tech-cell {
    margin: 5px;
    max-width: 50px;
    height: 50px;
  }
} */

/* services section styling */

.services,
.teams {
	color: var(--light-font);
	background: var(--dark-background);
}

body.dark-theme .services,
body.dark-theme .teams {
	color: var(--dark-font);
	background-color: var(--light-background);
}

.services .serv-content .card {
	background: var(--dark-card);
	overflow: hidden;
	text-align: center;
	width: 98%;
	border-radius: 6px;
	padding: 20px 25px;
	cursor: pointer;
	transition: all 0.3s ease;
	border: solid var(--border-color) 1px;
}

body.dark-theme .services .serv-content .card {
	background: var(--light-card);
}

.services .serv-content .card:hover {
	transform: scale(0.99);
}

.services .serv-content .card .box {
	transition: all 0.3s ease;
}

.services .serv-content .card:hover .box {
	transform: scale(1.05);
}

.services .serv-content .card i {
	font-size: 50px;
	color: var(--theme-color);
	transition: color 0.3s ease;
}

body.dark-theme .services .serv-content .card i {
	color: var(--theme-color-2);
}

.services .serv-content .card:hover i {
	color: var(--light-font);
}

body.dark-theme .services .serv-content .card:hover i {
	color: var(--dark-font);
}

.services .serv-content .card .text {
	font-size: 25px;
	font-weight: 500;
	margin: 10px 0 7px;
}

/* articles section styling */

.articles .carousel .card {
	border: solid var(--border-color) 1px;
	border-radius: 6px;
	text-align: center;
	overflow: hidden;
	transition: all 0.3s ease;
	width: 98%;
	height: 450px;
	padding: 20px;
}

.articles .max-width {
	padding: 0 70px;
}

.articles .carousel .card img {
	border-radius: 5px;
}

.articles .carousel .card .text {
	color: var(--theme-color);
	font-family: "Open Sans", sans-serif;
	font-size: 20px;
	text-align: left;
	margin-left: 5px;
	margin-top: 5px;
}

body.dark-theme .articles .carousel .card .text {
	color: var(--theme-color-2);
}

.articles .carousel .card p {
	text-align: left;
	font-size: 15px;
	font-family: "Open Sans", sans-serif;
	margin: 5px;
}

/* teams section styling */

.teams .carousel .card {
	border: solid var(--border-color) 1px;
	border-radius: 6px;
	padding: 25px 35px;
	text-align: center;
	overflow: hidden;
	transition: all 0.3s ease;
	width: 98%;
}

.articles .carousel .card a,
.teams .carousel .card a {
	color: var(--light-font);
}

body.dark-theme .articles .carousel .card a,
body.dark-theme .teams .carousel .card a {
	color: var(--dark-font);
}

.articles .carousel .card a::after,
.teams .carousel .card a::after {
	color: var(--light-font);
}

body.dark-theme .articles .carousel .card a::after,
body.dark-theme .teams .carousel .card a::after {
	color: var(--dark-font);
}

.articles .carousel .card:hover,
.teams .carousel .card:hover {
	background: var(--dark-card);
}

body.dark-theme .articles .carousel .card:hover,
body.dark-theme .teams .carousel .card:hover {
	background: var(--light-card);
}

.articles .carousel .card .box,
.teams .carousel .card .box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;
}

.articles .carousel .card:hover .box,
.teams .carousel .card:hover .box {
	transform: scale(1.05);
}

.teams .carousel .card .text {
	font-size: 25px;
	font-weight: 500;
	margin: 10px 0 7px;
}

.teams .carousel .card img {
	height: 150px;
	width: 150px;
	object-fit: cover;
	border-radius: 50%;
	border: 5px solid var(--theme-color-2);
	transition: all 0.3s ease;
}

.teams .carousel .card:hover img {
	border-color: var(--light-font);
}

.owl-dots {
	text-align: center;
	margin-top: 20px;
}

.owl-dot {
	height: 13px;
	width: 13px;
	margin: 0 5px;
	outline: none !important;
	border-radius: 50%;
	border: 2px solid var(--theme-color) !important;
	transition: all 0.3s ease;
}

body.dark-theme .owl-dot {
	border: 2px solid var(--theme-color-2) !important;
}

.owl-dot.active {
	width: 35px;
	border-radius: 14px;
}

.owl-dot.active,
.owl-dot:hover {
	background: var(--theme-color) !important;
}

body.dark-theme .owl-dot.active,
body.dark-theme .owl-dot:hover {
	background: var(--theme-color-2) !important;
}


/* responsive media query start */




@media (max-width: 345px) {
	.max-width {
		padding: 5px;
	}
	.services .serv-content .card {
		width: 100%;
	}

}
@media (min-width: 345px) and (max-width: 550px) {
	.home .home-content .text-1 {
		font-size: 1.2em;
	
	}

	.home .home-content .text-2 {
		font-size: 2.5em;
	}

	.home .home-content .text-3 {
		font-size: 1.2em;
	
	
	}

	.home .home-content .right {
		width: 100%;
	}

	.home .home-content .right img {
		width: 100%;
		object-fit: cover;
		border-radius: 6px;
		border: solid var(--border-color) 2px;
	}

	.signature {
		margin: 0 0 0 20px;
	}

}


@media (min-width: 550px) and (max-width: 700px) {
	
	.home .home-content .text-1 {
		font-size: 1.2em;
	}

	.home .home-content .text-2 {
		font-size: 3em;
	}

	.home .home-content .text-3 {
		font-size: 1.2em;

	}
	.max-width {
		padding: 0 23px;
	}

	.services .serv-content .card {
		width: 98%;
	}

}

/* Menu button active */
@media (max-width: 900px) {
	.container {
		width: 100%;
		padding: 20px;
	}

	.iframe {
		width: 100%;
	}
	
}
@media (min-width: 700px) and (max-width: 1100px) {
	/* .max-width {
		padding: 0 50px;
	} */

	.articles .max-width {
		padding: 0 20px;
	}

	.tech-stack .tech-stack-content {
		padding: 0;
		justify-content: center;
	}

	.tech-stack .tech-stack-content .tech-name {
		justify-self: center;
		margin-inline: 5px;
		margin-block-end: 20px;
		background-color: transparent;
		padding: 5px 10px;
		border-radius: 30px;
	}

	/* .max-width {
		max-width: 930px;
	} */

	.home .home-content .column {
		width: 100%;
	}

	.home .home-content .right {
		display: flex;
		justify-content: center;
		margin: 0 auto 60px;
	}

	.home .home-content .left {
		flex: 100%;
	}

	.home .home-content .text-1 {
		font-size: 1.5em;
	}

	.home .home-content .left .text-2 {
		font-size: 3.5em;
	}

	.home .home-content .left .text-3 {
		font-size: 1.5em;
	}

	.tech-stack .tech-stack-content .column {
		width: 100%;
		margin-bottom: 35px;
	}
	
	
}


@media (max-width: 1300px) {
	.home .max-width {
		margin-left: 0;
	}
}