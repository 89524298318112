@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");

@import "root.css";

.about.container {
	width: 100%;
	height: 400px;
	position: relative;
	vertical-align: text-bottom;
}

center .outer-circle {
	box-sizing: content-box;
	overflow: visible;
	position: absolute;
	width: 230px;
	height: 230px;
	border-color: transparent var(--avatar-border) var(--avatar-border) transparent;
	border-width: 55px;
	border-style: solid;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	box-shadow: var(--border-shadow-dark);
}

body.dark-theme center .outer-circle{
	box-shadow: 10px 10px 20px #e0e2e5;
}

center .outer-circle img {
	border-radius: 50%;
	width: 200px;
	height: 200px;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	box-shadow: var(--border-shadow-dark);
}

body.dark-theme center .outer-circle img{
	box-shadow: var(--border-shadow-light);
}

.outer-circle .fab,
.outer-circle .fas {
	font-size: 20px;
	color: var(--dark-font);
}

.outer-circle .fab:hover,
.outer-circle .fas:hover {
	color: var(--theme-color-2);
}

/* 12em = half the width of the wrapper */

center .outer-circle .fab.fa-discord {
	transform: translate(-110px, 230px);
  }
  
  center .outer-circle .fas.fa-globe {
	transform: translate(-70px, 248px);
  }
  
  center .outer-circle .fab.fa-codepen {
	transform: translate(35px, 238px);
  }
  
  center .outer-circle .fab.fa-dribbble {
	transform: translate(105px, 205px);
  }
  
  center .outer-circle .fab.fa-github {
	transform: translate(175px, 150px);
  }
  
  center .outer-circle .fab.fa-linkedin-in {
	transform: translate(200px, 90px);
  }
  
  center .outer-circle .fab.fa-instagram {
	transform: translate(140px, 30px);
  }
