/*  import google fonts */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,300;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

@import "root.css";

section.resources {
	padding: 10vh 0;
	color: var(--light-font);
	border-bottom: none;
}
/* background box for Introduction  */

.intro h1 {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: var(--light-font);
    font-size: 8vw;
    letter-spacing: .4vw;
}

body.dark-theme .intro h1 {
    color: var(--dark-font);
}

.intro h1 span {
    color: var(--theme-color);
}

.intro p {
    color: var(--light-font);
    font-family: Poppins;
    font-weight: lighter;
    font-size: 1.5vw;
    margin: 20px 0 0 20px;
    padding: 0 150px;
}

body.dark-theme .intro p {
    color: var(--dark-font);
}


/* background box for tags  */

.tagbox {
    display: flex;

    justify-content: center;
    background-color: var(--dark-card);
    padding: 20px;
    margin-top: 0;
    border-radius: 10px;
    /* border: solid var(--border-color) 1px; */
    font-family: Comfortaa;
    color: var(--dark-font);
    margin: 30px;
    box-shadow: var(--border-shadow-dark);
}

body.dark-theme .tagbox {
    background-color: var(--light-card);
    box-shadow: var(--border-shadow-light);
}

.tagbox ul {
    list-style-type: none;
    position: relative;
    align-items: center;
    justify-content: center;
}

.tagbox ul li {
    background-color: var(--dark-background);
    color: var(--light-font);
    /* border: 2px solid var(--border-color); */
    box-shadow: var(--button-shadow-in-dark);
    border-radius: 50px;
    padding: 4px 6px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-family: Poppins;
    margin: 10px;
    transition-duration: 0.4s;
    cursor: pointer;
    outline: none;
    float: left;
}

body.dark-theme .tagbox ul li{
    background-color: var(--light-background);
    box-shadow: var(--button-shadow-in-light);
    color: var(--dark-font);
}

.badge {
    background-color: var(--light-background);
    color: var(--dark-font);
    padding: 0 7px;
    border-radius: 20px;
}

body.dark-theme .badge {
    background-color: var(--dark-background);
    
    color: var(--light-font);
}

.btn:hover,
.btn.active {
    box-shadow: var(--button-shadow-out-dark);
    color: var(--light-font);
    outline: none;
}

body.dark-theme .btn:hover,
body.dark-theme .btn.active {
    box-shadow: var(--button-shadow-out-light);
    color: var(--dark-font);
    
}


/* Float four columns side by side */

.card.webdev,
.card.elearning,
.card.python,
.card.design,
.card.coding,
.card.cheatsheets,
.card.sql,
.card.queries {
    float: left;
    width: 25%;
    padding: 0 20px;
    margin-block-end: 20px;
    margin-bottom: 40px;
    /* overflow: hidden; */
}


/* Remove extra left and right margins, due to padding */

.row {
    padding: 30px;
}


/* Clear floats after the columns */

.row:after {
    content: "";
    display: table;
    clear: both;
}

.new {
    position: absolute;
    color: white;
    font-family: Poppins;
    padding: 0 5px;
    margin: 0 0 0 15px;
    border-radius: 0 0 5px 5px;
    width: fit-content;
    background-color: crimson;
    transform: translateY(-1.2rem);
    transition: transform .3s linear;
    box-shadow: var(--button-shadow-out-light);
}

.box:hover > .new{ transform: translateY(-0.2rem); transition: transform .3s linear;}

/* Style the counter cards */

.resources .box {
    position: relative;
    height: fit-content;
    background: var(--dark-card);
    border-radius: 15px;
    /* border: solid var(--border-color) 1px; */
    box-shadow: var(--border-shadow-dark);
    text-align: center;
    overflow: hidden;
    transition: all 0.3s ease;
    color: var(--light-font);
    padding: 0 10px 10px;
}

body.dark-theme .resources .box {
    background: var(--light-card);
    color: var(--dark-font);
    box-shadow: var(--border-shadow-light);
}

.resources .box:hover {
    border-color: var(--border-color-2);
}


.resources .box img {
    width: 100%;
    /* border: dashed var(--border-color) 1px; */
    border-radius: 15px;
    margin: 30px 0 10px;
    overflow: hidden;
    position: relative;
    opacity: 100%;
    transition: opacity .4s ease;
}

.resources .box:hover img {
    opacity: 30%;
    
}

.resources .box:hover p {
    opacity: 100%;
}

.resources .box p {
    width: 80%;
    font-family: Poppins, sans-serif;
    color: var(--light-font);
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity .4s ease;
    font-size: 1.1vw;
}

body.dark-theme .resources p {
    color: var(--dark-font);
}

.resources .box a {
    color: var(--light-font);
    white-space: nowrap;
    font-family: Poppins;
    font-size: medium;
    letter-spacing: .1em;
    line-height: 1.25;
    margin: 0 0 .75em;
    padding: .75em 0 0;
    clear: both;
}

body.dark-theme .resources .box a {
    color: var(--dark-font);
}

/*Hyperlinks Decoration*/

/* .box a:link {
    color: var(--dark-links);
    background-color: transparent;
    text-decoration: none;
}

body.dark-theme .box a:link {
    color: var(--light-links);
    background-color: transparent;
    text-decoration: none;
} */

.resources .box a:visited {
    color: var(--dark-links);
    background-color: transparent;
    text-decoration: none;
}

body.dark-theme .resources .box a:visited {
    color: var(--light-links);
    background-color: transparent;
    text-decoration: none;
}

.resource .box a:hover {
    color: var(--links-hover);
    background-color: transparent;
    text-decoration: none;
}

body.dark-theme .resources .box a:hover {
    color: var(--links-hover);
    background-color: transparent;
    text-decoration: none;
}

.resources .box a:active {
    color: #d1c5f1;
    background-color: transparent;
    text-decoration: none;
}

body.dark-theme .resources .box a:active {
    color: #d1c5f1;
    background-color: transparent;
    text-decoration: none;
}

section.long-width-ad {
    background-size: contain;
    background-repeat: no-repeat;
    height: 50vh !important;
    margin: 5vh 0;
    box-shadow: var(--border-shadow-dark);
    border-radius: 20px;
    overflow: hidden;
}

body.dark-theme section.long-width-ad{
    box-shadow: var(--border-shadow-light);
}

/* Responsive columns */

@media (max-width: 600px) {
    .card.webdev,
    .card.elearning,
    .card.python,
    .card.design,
    .card.coding,
    .card.cheatsheets,
    .card.queries, .card.sql {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        padding: 0;
    }

    .tagbox ul {
        display: block;
    }

    .intro h1 {
        font-size: 15vw;
    }

    .intro p {
        font-family: Poppins;
        font-weight: lighter;
        margin: 10px 20px 0;
        padding: 0;
        font-size: 4vw;
    }

    .resources .box p {
        font-size: 4vw;
    }

    section.long-width-ad {
        height: fit-content !important;
    }
}


/* responsive media query start */


@media (min-width: 600px) and (max-width: 900px) {
    .max-width {
        padding: 0 50px;
    }

    .intro p {
        margin: 20px 50px 0;
        font-size: 2.5vw;
        padding: 0;
    }

    .resources .box p {
        font-size: 2vw;
    }

    .card.webdev,
    .card.elearning,
    .card.python,
    .card.design,
    .card.coding,
    .card.cheatsheets,
    .card.sql,
    .card.queries {
        float: left;
        width: 50%;
        padding: 0 10px;
        margin-block-end: 20px;
    }
}

@media (min-width: 900px) and (max-width: 1200px) {
    .intro p{
        font-family: Poppins;
        font-weight: lighter;
        font-size: 2vw;
        margin: 20px 20px 0;
        padding: 0 50px;
    }

    .card.webdev,
    .card.elearning,
    .card.python,
    .card.design,
    .card.coding,
    .card.cheatsheets,
    .card.queries,.card.sql {
        float: left;
        width: 33%;
        padding: 0 10px;
        margin-block-end: 20px;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .card.webdev,
    .card.elearning,
    .card.python,
    .card.design,
    .card.coding,
    .card.cheatsheets,
    .card.queries, .card.sql {
        float: left;
        width: 25%;
        padding: 0 10px;
        margin-block-end: 20px;
    }

    
}

@media (max-width: 500px) {
    .scroll-up-btn {
        right: 15px;
        bottom: 15px;
        height: 38px;
        width: 35px;
        font-size: 23px;
        line-height: 38px;
    }
}

@media (max-width: 690px) {
    .max-width {
        padding: 0 23px;
    }
}

@media (max-width: 360px){
    .tagbox{
        padding: 0;
    }
}
