/*  import google fonts */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,300;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import "root.css";

a.featured-article {
	margin: 50px;
	max-width: 100%;
	min-width: 100px;
	position: relative;
	/* border: solid 2px var(--border-color); */
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--light-font);
}

body.dark-theme a.featured-article {
	color: var(--dark-font);
}

.featured-article .featured-left {
	overflow: hidden;
	position: absolute;
	height: 100%;
	left: 0;
	display: flex;
	border-radius: 1em;
	width: 60%;
	border: solid var(--border-color) 1px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.featured-article .featured-left:hover {
	animation: vibrate 0.5s linear both;
}

@keyframes vibrate {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translate(-2px, 2px);
	}
	40% {
		transform: translate(-2px, -2px);
	}
	60% {
		transform: translate(2px, 2px);
	}
	80% {
		transform: translate(2px, -2px);
	}
	100% {
		transform: translate(0);
	}
}
.featured-article .featured-left img {
	width: 100%;
	height: 100%;
}

body.dark-theme .featured-article .featured-left img {
	opacity: 0.5;
}

.featured-article .featured-left:hover {
	border: dashed var(--border-color) 1px;
}

.featured-article .featured-right {
	position: absolute;
	width: 60%;
	right: 0;
	text-align: right;
	font-size: 1em;
}

.featured-article .featured-tag {
	color: var(--theme-color);
	font-weight: 700;
}

.featured-article h1,
.article h1,
.article p,
.article small {
	margin: 1rem;
}

.featured-article h1 {
	font-size: 2em;
	font-weight: 900;
}

.featured-article .description {
	background-color: var(--dark-card);
	width: 100%;
	padding: 25px 20px;
	border-radius: 0.5em;
	color: var(--light-font);
	font-style: italic;
	font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
		sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
	box-shadow: var(--border-shadow-dark);
}

body.dark-theme .featured-article .description {
	background-color: var(--light-article);
	color: var(--dark-font);
	box-shadow: var(--border-shadow-light);
}

.featured-article .tags {
	position: relative;
	margin: 10px 0;
	overflow: hidden;
	width: 100%;
	right: 0;
}

.featured-article .tags small {
	font-family: monospace;
	font-size: 1rem;
	margin-inline-start: 1em;
	margin-top: 10px;
	float: right;
	word-spacing: 0.1em;
}

section.article-list {
	margin: 0 100px 50px;
	max-width: 100%;
	min-width: 100px;
}

.infinite-scroll-component {
	padding: 20px 0;
}

.article-list .article-card {
	float: left;
	width: 50%;
	padding: 0 30px;
	margin-block-end: 50px;
	justify-content: space-between;
	align-content: flex-start;
}

/* Clear floats after the columns */

.article-list:after {
	content: "";
	display: inline-table;
	clear: both;
}

.article-list .article {
	position: relative;
	min-height: 550px;
	height: fit-content;
	background: var(--dark-card);
	border-radius: 10px;
	/* border: solid 1px var(--border-color); */
	box-shadow: var(--border-shadow-dark);
	overflow: hidden;
	transition: all 0.3s ease;
	color: var(--light-font);
	/* padding: 10px; */
	word-wrap: break-word;
	animation: scale-up-bottom 1s;
}

@keyframes scale-up-bottom {
	0% {
		transform: scale(0.5);
		transform-origin: center bottom;
	}
	100% {
		transform: scale(1);
		transform-origin: center bottom;
	}
}

body.dark-theme .article-list .article {
	background: var(--light-article);
	color: var(--dark-font);
	box-shadow: var(--border-shadow-light);
}

/* .article-list .article:hover {
	
} */

.article img {
	width: 100%;
	overflow: hidden;
	position: relative;
	opacity: 100%;
	transition: opacity 0.4s ease;
}

.article small {
	position: absolute;
	bottom: 0;
}

@media (max-width: 600px) {
	a.featured-article {
		margin: 50px 10px;
		height: 450px;
	}

	.featured-article .featured-left {
		width: 100%;
		position: relative;
		overflow: hidden;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.featured-article .featured-left img {
		opacity: 0.2;
		width: fit-content;
	}

	.featured-article .featured-right {
		width: 90%;
		margin: 0 20px;
	}

	.featured-article h1 {
		font-size: 1.5em;
	}

	.featured-article p {
		font-size: 1.2rem;
	}

	section.article-list {
		margin: 5px;
	}

	.article-list .article-card {
		width: 100%;
		width: fit-content;
		display: block;
	}
}
