@import "root.css";

.loader-section{
    display: flex;
    justify-content: center;
    padding: 30vh 0;
    height: 80vh;
}
.loader {
	--path: var(--light-font);
	--dot: var(--theme-color);
	--duration: 3s;
	width: 44px;
	height: 44px;
	position: relative;
}

body.dark-theme .loader{
	--path: var(--dark-font);
}


.loader:before {
	content: "";
	width: 6px;
	height: 6px;
	border-radius: 50%;
	position: absolute;
	display: block;
	background: var(--dot);
	top: 37px;
	left: 30px;
	transform: translate(-18px, -18px);
	-webkit-animation: dotRect var(--duration)
		cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
	animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
		infinite;
}
.loader svg {
	display: block;
	width: 100%;
	height: 100%;
}
.loader svg rect,
.loader svg polygon,
.loader svg circle {
	fill: none;
	stroke: var(--path);
	stroke-width: 10px;
	stroke-linejoin: round;
	stroke-linecap: round;
}
.loader svg polygon {
	stroke-dasharray: 145 76 145 76;
	stroke-dashoffset: 0;
	-webkit-animation: pathTriangle var(--duration)
		cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
	animation: pathTriangle var(--duration)
		cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}
.loader svg rect {
	stroke-dasharray: 192 64 192 64;
	stroke-dashoffset: 0;
	-webkit-animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86)
		infinite;
	animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}
.loader svg circle {
	stroke-dasharray: 150 50 150 50;
	stroke-dashoffset: 75;
	-webkit-animation: pathCircle var(--duration)
		cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
	animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86)
		infinite;
}
.loader.triangle {
	width: 48px;
}
.loader.triangle:before {
	left: 30px;
	transform: translate(-10px, -18px);
	-webkit-animation: dotTriangle var(--duration)
		cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
	animation: dotTriangle var(--duration)
		cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

@-webkit-keyframes pathTriangle {
	33% {
		stroke-dashoffset: 74;
	}
	66% {
		stroke-dashoffset: 147;
	}
	100% {
		stroke-dashoffset: 221;
	}
}

@keyframes pathTriangle {
	33% {
		stroke-dashoffset: 74;
	}
	66% {
		stroke-dashoffset: 147;
	}
	100% {
		stroke-dashoffset: 221;
	}
}
@-webkit-keyframes dotTriangle {
    33% {
      transform: translate(0, 0);
    }
    66% {
      transform: translate(10px, -18px);
    }
    100% {
      transform: translate(-10px, -18px);
    }
  }
@keyframes dotTriangle {
    33% {
      transform: translate(0, 0);
    }
    66% {
      transform: translate(10px, -18px);
    }
    100% {
      transform: translate(-10px, -18px);
    }
  }
@-webkit-keyframes pathRect {
	25% {
		stroke-dashoffset: 64;
	}
	50% {
		stroke-dashoffset: 128;
	}
	75% {
		stroke-dashoffset: 192;
	}
	100% {
		stroke-dashoffset: 256;
	}
}
@keyframes pathRect {
	25% {
		stroke-dashoffset: 64;
	}
	50% {
		stroke-dashoffset: 128;
	}
	75% {
		stroke-dashoffset: 192;
	}
	100% {
		stroke-dashoffset: 256;
	}
}
@-webkit-keyframes dotRect {
    25% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(18px, -18px);
    }
    75% {
      transform: translate(0, -36px);
    }
    100% {
      transform: translate(-18px, -18px);
    }
  }
@keyframes dotRect {
    25% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(18px, -18px);
    }
    75% {
      transform: translate(0, -36px);
    }
    100% {
      transform: translate(-18px, -18px);
    }
  }
@-webkit-keyframes pathCircle {
	25% {
		stroke-dashoffset: 125;
	}
	50% {
		stroke-dashoffset: 175;
	}
	75% {
		stroke-dashoffset: 225;
	}
	100% {
		stroke-dashoffset: 275;
	}
}
@keyframes pathCircle {
	25% {
		stroke-dashoffset: 125;
	}
	50% {
		stroke-dashoffset: 175;
	}
	75% {
		stroke-dashoffset: 225;
	}
	100% {
		stroke-dashoffset: 275;
	}
}
.loader {
	display: inline-block;
	margin: 0 25px 0 0;
}

