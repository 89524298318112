/* theme changer button*/

.switch {
	display: block;
	position: absolute;
	margin-left: 25px;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.switch__input {
	clip: rect(0.5px, 0.5px, 0.5px, 0.5px);
	clip-path: inset(50%);
	height: 0.5px;
	width: 0.5px;
	margin: -0.5px;
	overflow: hidden;
	padding: 0;
	position: absolute;
}

.switch__label {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 30px;
	background-color: #2b2b2b;
	border: 2.5px solid #5b5b5b;
	border-radius: 4444px;
	cursor: pointer;
	transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.switch__indicator {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) translateX(-72%);
	display: block;
	width: 20px;
	height: 20px;
	background-color: #7b7b7b;
	border-radius: 4444px;
	box-shadow: 5px 0 0 0 rgba(0, 0, 0, 0.2) inset;
}

.switch__indicator::before,
.switch__indicator::after {
	position: absolute;
	content: "";
	display: block;
	background-color: #ffffff;
	border-radius: 4444px;
}

.switch__indicator::before {
	top: 3.5px;
	left: 3.5px;
	width: 4.5px;
	height: 4.5px;
	background-color: #ffffff;
	opacity: 0.6;
}

.switch__indicator::after {
	bottom: 4px;
	right: 3px;
	width: 7px;
	height: 7px;
	background-color: #ffffff;
	opacity: 0.8;
}

.switch__decoration {
	position: absolute;
	top: 65%;
	left: 50%;
	display: block;
	width: 2.5px;
	height: 2.5px;
	background-color: #ffffff;
	border-radius: 4444px;
	animation: twinkle 0.8s infinite -0.6s;
}

.switch__decoration::before,
.switch__decoration::after {
	position: absolute;
	display: block;
	content: "";
	width: 2.5px;
	height: 2.5px;
	background-color: #ffffff;
	border-radius: 4444px;
}

.switch__decoration::before {
	top: -10px;
	left: 5px;
	opacity: 1;
	animation: twinkle 0.6s infinite;
}

.switch__decoration::after {
	top: -3.5px;
	left: 15px;
	animation: twinkle 0.6s infinite -0.2s;
}

@keyframes twinkle {
	50% {
		opacity: 0.2;
	}
}

.switch__indicator,
.switch__indicator::before,
.switch__indicator::after {
	transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.switch__input:checked + .switch__label {
	background-color: #8fb5f5;
	border-color: #347cf8;
}

.switch__input:checked + .switch__label .switch__indicator {
	background-color: #ecd21f;
	box-shadow: none;
	transform: translate(-50%, -50%) translateX(72%);
}

.switch__input:checked + .switch__label .switch__indicator::before,
.switch__input:checked + .switch__label .switch__indicator::after {
	display: none;
}

.switch__input:checked + .switch__label .switch__decoration {
	top: 50%;
	transform: translate(0%, -50%);
	animation: cloud 8s linear infinite;
	width: 10px;
	height: 10px;
}

.switch__input:checked + .switch__label .switch__decoration::before {
	width: 5px;
	height: 5px;
	top: auto;
	bottom: 0;
	left: -4px;
	animation: none;
}

.switch__input:checked + .switch__label .switch__decoration::after {
	width: 7.5px;
	height: 7.5px;
	top: auto;
	bottom: 0;
	left: 8px;
	animation: none;
}

.switch__input:checked + .switch__label .switch__decoration,
.switch__input:checked + .switch__label .switch__decoration::before,
.switch__input:checked + .switch__label .switch__decoration::after {
	border-radius: 4444px 4444px 0 0;
}

.switch__input:checked + .switch__label .switch__decoration::after {
	border-bottom-right-radius: 4444px;
}

@keyframes cloud {
	0% {
		transform: translate(0%, -50%);
	}

	50% {
		transform: translate(-50%, -50%);
	}

	100% {
		transform: translate(0%, -50%);
	}
}

/* media query */
@media (max-width: 900px) {
    .switch {
        position: relative;
        margin-left: 0;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(50%);
    }
}

