/*  import google fonts */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,300;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import "root.css";

section.post {
	overflow: hidden;
	padding: 0 150px 50px;
	color: var(--light-font);
	font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial,
		sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.post h1 {
	font-size: 3em;
	font-weight: 900;
	text-align: center;
	padding: 1em 1em 0;
}

.post .info {
	text-align: center;
	font-size: 1.5em;
	padding: 1em;
}

.post img {
	max-width: 100%;
	min-width: 100px;
	margin: 1em 0;
}

.post p {
	font-size: 1.5em;
	line-height: 1.5em;
	padding: 0.5em 0;
}

.post h3 {
	font-size: 2.1em;
	line-height: 2em;
}
.post h4 {
	font-size: 1.6em;
}
.post blockquote {
	border-left: solid 0.5em var(--theme-color);
	padding: 0 1vw;
	background-color: var(--dark-card);
	font-style: italic;
	margin: 1em 0;
}

body.dark-theme .post blockquote {
	background-color: var(--light-card);
}

.post ul,
.post ol {
	padding-left: 2vw;
	font-size: 1.5em;
	line-height: 1.5em;
	margin: 1em 0;
}

.post a {
	color: var(--theme-color);
	/* text-decoration:dashed underline  var(--theme-color); */
	border-bottom: 2px dashed;
}
.post code {
	background-color: var(--code-background-inline-dark);
	border-radius: 0.3em;
	font-size: 0.8em;
	font-family: "Source Code Pro", monospace;
	padding: 0 0.5em;
	word-wrap: break-word;
	box-decoration-break: clone;
	color: var(--dark-font);
}

body.dark-theme .post code {
	background-color: var(--code-background-inline-dark);
	color: var(--dark-font);
}

.post pre {
	border-radius: 0.3em;
	font-family: "Source Code Pro", monospace;
	margin: 2rem 0;
}

.post pre code {
	display: block;
	color: var(--dark-font);
	background-color: var(--code-background-inline-light);
	font-size: 1em;
	word-wrap: break-word;
	box-decoration-break: clone;
	white-space: pre;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	max-width: 100%;
	min-width: 100px;
	padding: 2em;
}

.post .tags {
	position: relative;
	margin:1em 0;
	overflow: hidden;
	width: 100%;
	padding: 0 0.5em 0.5em;
	border-top: solid 0.2em var(--border-color);
	border-bottom: solid 0.2em var(--border-color);
	
}

.post .tags small {
	font-family: monospace;
	font-size: 1rem;
	margin-inline-end: 1em;
	margin-top: 0.5em;
	word-spacing: 0.1em;
	float: left;
}


@media (max-width: 700px) {
	section.post {
		padding: 1.5em;
	}
	.post h1 {
		font-size: 2em;
		padding: 0.5em 0.5em 0;
	}
	.post .info {
		font-size: 1em;
		padding: 0.5em;
	}
	.post p {
		font-size: 1.2em;
		padding: 0.5em 0;
	}
	.post h3 {
		font-size: 2em;
		line-height: 1.5em;
	}

	.post ul,
	.post ol {
		font-size: 1.2em;
		padding-left: 5vw;
	}
}

@media (min-width: 701px) and (max-width:900px){
	section.post {
		padding: 5em;
	}

	.post h1 {
	
		padding: 0.5em 0.5em 0;
	}
}
