@import "root.css";
@import url("https://fonts.googleapis.com/css?family=Jura:400");

.dashboard-page{
    position: relative;
    padding: 1em;
    width: 100%;
    text-align: center;
    height: 80vh;
    color: var(--light-font);
}

body.dark-theme .dashboard-page{
    color: var(--dark-font);
}

.dashboard-page p{
    margin: 1em;
}
.dashboard-page .logout{
    color: var(--light-font);
    box-shadow: var(--button-shadow-in-dark);
    padding: 10px 15px;
	margin-top: 40px;
    border-radius: 1.5em;
}

body.dark-theme .dashboard-page .logout {
	color: var(--dark-font);
	box-shadow: var(--button-shadow-in-light);
}

.dashboard-page .logout:hover {
	color: var(--theme-color);
	box-shadow: var(--button-shadow-out-dark);
}

body.dark-theme .dashboard-page .logout:hover {
	color: var(--theme-color);
	box-shadow: var(--button-shadow-out-light);
}

.dashboard-page .maintainance{
    margin: 10vw;
    text-align: center;
}
