
/* projects section styling */

.projects{
	padding: 2em 0;
}

.featured {
	position: relative;
	width: 100%;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
}

.featured .left-block {
	position: absolute;
	width: 60%;
	height: 100%;
	left: 0;
	overflow: hidden;
	border: solid var(--border-color) 1px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.featured .left-block img {
	height: 100%;
}
.featured .left-block:hover {
	border: dashed var(--border-color) 1px;
}

.featured .right-block {
	position: absolute;
	right: 0;
	width: 50%;
	text-align: right;
	font-family: "Open Sans", sans-serif;
}
.featured .right-block h5 {
	color: var(--theme-color);
}
.featured .right-block .text {
	font-size: 30px;
	font-weight: 500;
	margin: 10px 0 15px;
}
.featured .right-block .description {
	background-color: var(--dark-card);
	width: 100%;
	padding: 25px 20px;
	border-radius: 5px;
	color: var(--light-font);
	box-shadow: var(--border-shadow-dark);
}

body.dark-theme .featured .right-block .description{
	background-color: var(--light-card);
	color: var(--dark-font);
	box-shadow: var(--border-shadow-light);
}

.featured .right-block .tags {
	position: relative;
	margin: 10px 0;
	overflow: hidden;
	width: 100%;
	right: 0;
}

.featured .right-block .tags p {
	font-family: monospace;
	font-size: 15px;
	margin-inline-start: 10px;
	margin-top: 10px;
	float: right;
}

svg {
	overflow: hidden;
	width: 25px;
	margin-inline-start: 10px;
	color: var(--theme-color);
}

/* repo list */

/* Remove extra left and right margins, due to padding */

.list {
	list-style-type: none;
	font-family: "Open Sans", sans-serif;
}

/* Clear floats after the columns */

.list:after {
	content: "";
	display: table;
	clear: both;
}
/* Style the counter cards */

.list .card {
	float: left;
	width: 33%;
	padding: 0 10px;
	margin-block-end: 20px;
	position: relative;
	overflow: visible;
}

.list .box {
	text-align: left;
	height: 300px;
	background: var(--dark-card);
	border-radius: 5px;
	transition: all 0.3s ease;
	color: var(--light-font);
	padding: 20px;
	position: relative;
	overflow: hidden;
	box-shadow: var(--border-shadow-dark);
	border: solid transparent 1px;
}

body.dark-theme .list .box {
	box-shadow: var(--border-shadow-light);
	background: var(--light-card);
	border: solid transparent 1px;
	color: var(--dark-font);
} 

.list .box:hover {
	border: solid var(--border-color) 1px;
}
body.dark-theme .list .box:hover {
	border: solid var(--border-color) 1px;
}

.list h3 {
	font-size: 40px;
}

.list .box i {
	font-size: 40px;
}
.list .box svg {
	float: right;
}

.box .project-name {
	word-wrap: break-word;
	font-size: 20px;
	font-weight: 600;
	margin: 15px 0;
	clear: both;
}

.list .box p {
	font-size: 15px;
	font-weight: 200;
}

.list .box .tags {
	position: absolute;
	margin: 30px 0 40px;
	overflow: hidden;
	width: 100%;
	bottom: 0;
}
.list .tags p {
	font-family: monospace;
	font-size: 13px;
	margin-inline-end: 10px;
	margin-block-end: 5px;
	float: left;
}


/* responsive media query start */




@media (max-width: 345px) {
	.max-width {
		padding: 5px;
	}
	.services .serv-content .card {
		width: 100%;
	}
	.list .card {
		width: 100%;
	}
	.list {
		padding: 20px;
	}
}
@media (min-width: 345px) and (max-width: 600px) {
	
	.list .card {
		width: 100%;
	}
	.list {
		padding: 0;
	}
}


@media (min-width: 600px) and (max-width: 900px) {
	.list {
		padding: 0;
	}
	.list .card {
		width: 50%;
		padding: 10;
	}
}

@media (max-width: 900px) {
	.featured {
		height: 450px;
	}
	.featured .left-block {
		width: 100%;
		position: relative;
		overflow: hidden;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.featured .left-block img {
		opacity: 0.2;
	}
	.featured .right-block {
		width: 90%;
		margin: 0 20px;
	}
	
}

@media (max-width: 1300px) {
	.home .max-width {
		margin-left: 0;
	}
}