
@import "root.css";

/* tech-stack section styling */

section.tech-stack{
    height: 80vh;
    display: flex;
    justify-items: center;
    
}

.wrapper {
	width: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
}

.tech-container {
	display: flex;
	float: left;
	flex-wrap: wrap;
	list-style: none;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	margin: 0 auto;
	padding: 0;
	transform: translateY(13.75px);
	right: 0;
}

.tech-cell {
	background-color: var(--tech-cell);
	border-radius: 50%;
	box-shadow: var(--border-shadow-dark);
	flex: 0 1 100px;
	max-width: 100px;
	height: 100px;
	margin: 25px 10px;
	position: relative;
	overflow: hidden;
	text-align: center;
	margin-top: 0;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

body.dark-theme .tech-cell {
	box-shadow: var(--border-shadow-light);
	background-color: var(--light-card);
}

.tech-cell img {
	width: 60%;
	-o-object-fit: scale-down;
	object-fit: scale-down;
	-o-object-position: center;
	object-position: center;
}

@media (max-width: 600px) {

	.wrapper {
		display: block;
		overflow: hidden;
	}
	.tech-container {
		overflow: hidden;
		float: none;
		width: 100%;
		max-width: 100%;
		position: relative;
		padding: 0;
		margin: 0;
	}
	.tech-cell {
		margin: 5px;
		max-width: 50px;
		height: 50px;
	}

	.chart {
		float: none;
		width: 100%;
		margin: 0;
	}
}


@media (min-width: 700px) and (max-width: 1100px) {
	.tech-stack .tech-stack-content {
		padding: 0;
		justify-content: center;
	}

	.tech-stack .tech-stack-content .tech-name {
		justify-self: center;
		margin-inline: 5px;
		margin-block-end: 20px;
		background-color: transparent;
		padding: 5px 10px;
		border-radius: 30px;
	}

	.tech-stack .tech-stack-content .column,
	.contact .contact-content .column {
		width: 100%;
		margin-bottom: 35px;
	}
	
	
}

